import { type ApuProgramDto } from '../../../models'
import { EditableInputCellWithLabelWithLabel, ErrorBoundary, InputType } from '@flyward/platform'
import { type Control } from 'react-hook-form'
import { MaintenanceProgramDetailsArea } from '../MaintenanceProgramDetailsArea'
import { AuxiliaryPowerUnitChecksEditableTable } from './AuxiliaryPowerUnitChecksTable/AuxiliaryPowerUnitChecksEditableTable'
import { InflationEditable } from '../InflationEditable'
import { BasicAssumptionsEditable } from '../BasicAssumptionsEditable'
import { isNil } from 'lodash'

interface IAuxiliaryPowerUnitMaintenanceProgramEditableProps {
  auxiliaryPowerUnitMaintenanceProgram: ApuProgramDto | undefined
  formControl: Control<ApuProgramDto, unknown>
}

export const AuxiliaryPowerUnitMaintenanceProgramEditable = ({
  auxiliaryPowerUnitMaintenanceProgram,
  formControl,
}: IAuxiliaryPowerUnitMaintenanceProgramEditableProps) => {
  return (
    <ErrorBoundary>
      <MaintenanceProgramDetailsArea title="Auxiliary Power Unit">
        <div className="flex gap-6">
          <EditableInputCellWithLabelWithLabel className="basis-1/6" label="Manufacturer" formControl={formControl} fieldName={`manufacturer`} />
          <EditableInputCellWithLabelWithLabel
            className="basis-1/6"
            label="AuxiliaryPowerUnit Model"
            formControl={formControl}
            fieldName={`compatibleComponentModels`}
          />
          <EditableInputCellWithLabelWithLabel
            inputType={InputType.NaturalNumber}
            className="basis-1/6"
            label="Base year"
            formControl={formControl}
            fieldName={`kbReferenceYear`}
          />
        </div>
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Checks">
        <AuxiliaryPowerUnitChecksEditableTable
          checks={!isNil(auxiliaryPowerUnitMaintenanceProgram) ? [auxiliaryPowerUnitMaintenanceProgram.check] : []}
          formControl={formControl}
        />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Inflation">
        <InflationEditable formControl={formControl} percentageFieldName={`inflation.percentage`} />
      </MaintenanceProgramDetailsArea>
      <MaintenanceProgramDetailsArea title="Basic Assumptions">
        <BasicAssumptionsEditable
          formControl={formControl}
          downtimeFieldPath={'basicAssumptions.checkDowntimeMonths'}
          delayFieldPath={'basicAssumptions.monthsDelayInPaymentOnClaims'}
        />
      </MaintenanceProgramDetailsArea>
    </ErrorBoundary>
  )
}
