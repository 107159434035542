/* eslint-disable max-len */
import { isEmpty } from 'lodash'
import { ComponentTypesAPIURIs } from '../models'

const _baseApiUrl: string = isEmpty(import.meta.env.VITE_API_URL) ? '/api' : import.meta.env.VITE_API_URL
const _appIdentityModuleBaseUrl = _baseApiUrl + '/'
const _assetsModuleBaseModuleUrl = _baseApiUrl + '/'
const _forecastBaseModuleUrl = _baseApiUrl + '/'
const _knowledgeBaseBaseModuleUrl = _baseApiUrl + '/'

export const APIRoutes = {
  AppIdentityModule: {
    AuthController: {
      _authControllerBaseUrl: `${_appIdentityModuleBaseUrl}auth/`,
      Login: () => `${APIRoutes.AppIdentityModule.AuthController._authControllerBaseUrl}login`,
      RefreshToken: () => `${APIRoutes.AppIdentityModule.AuthController._authControllerBaseUrl}refresh-token`,
      Register: () => `${APIRoutes.AppIdentityModule.AuthController._authControllerBaseUrl}register`,
      ResetPassword: () => `${APIRoutes.AppIdentityModule.AuthController._authControllerBaseUrl}reset-password`,
    },
    UserController: {
      _userControllerBaseUrl: `${_appIdentityModuleBaseUrl}users/`,
      GetAllUsersNames: () => `${APIRoutes.AppIdentityModule.UserController._userControllerBaseUrl}names`,
      GetMe: () => `${APIRoutes.AppIdentityModule.UserController._userControllerBaseUrl}me`,
      GetAll: () => `${APIRoutes.AppIdentityModule.UserController._userControllerBaseUrl}`,
      AddUserEdit: () => `${APIRoutes.AppIdentityModule.UserController._userControllerBaseUrl}`,
      ChangeState: () => `${APIRoutes.AppIdentityModule.UserController._userControllerBaseUrl}change-state`,
      ResendInvitation: () => `${APIRoutes.AppIdentityModule.UserController._userControllerBaseUrl}resend-invitation`,
    },
    TenantController: {
      _tenantControllerBaseUrl: `${_appIdentityModuleBaseUrl}tenant/`,
      GetAllTenants: () => `${APIRoutes.AppIdentityModule.TenantController._tenantControllerBaseUrl}`,
      CreateTenant: () => `${APIRoutes.AppIdentityModule.TenantController._tenantControllerBaseUrl}`,
      ChangeState: () => `${APIRoutes.AppIdentityModule.TenantController._tenantControllerBaseUrl}change-state`,
    },
  },
  AssetsModule: {
    Components: {
      _componentsBaseUrl: (assetId: string) => `${_assetsModuleBaseModuleUrl}assets/${assetId}/`,

      AirframesController: {
        _airframesBaseUrl: (assetId: string) => `${APIRoutes.AssetsModule.Components._componentsBaseUrl(assetId)}${ComponentTypesAPIURIs.Airframe}/`,
        GetAll: (assetId: string) => APIRoutes.AssetsModule.Components.AirframesController._airframesBaseUrl(assetId),
        GetExpandedAirframes: (assetId: string) => `${APIRoutes.AssetsModule.Components.AirframesController._airframesBaseUrl(assetId)}expanded`,
        Update: (assetId: string) => `${APIRoutes.AssetsModule.Components.AirframesController._airframesBaseUrl(assetId)}`,
      },

      AuxiliaryPowerUnitsController: {
        _auxiliaryPowerUnitBaseUrl: (assetId: string) =>
          `${APIRoutes.AssetsModule.Components._componentsBaseUrl(assetId)}${ComponentTypesAPIURIs.AuxiliaryPowerUnit}/`,
        GetAll: (assetId: string) => APIRoutes.AssetsModule.Components.AuxiliaryPowerUnitsController._auxiliaryPowerUnitBaseUrl(assetId),
        GetExpandedAuxiliaryPowerUnits: (assetId: string) =>
          `${APIRoutes.AssetsModule.Components.AuxiliaryPowerUnitsController._auxiliaryPowerUnitBaseUrl(assetId)}expanded`,
        Update: (assetId: string) => `${APIRoutes.AssetsModule.Components.AuxiliaryPowerUnitsController._auxiliaryPowerUnitBaseUrl(assetId)}`,
      },

      EnginesController: {
        _engineUnitBaseUrl: (assetId: string) =>
          `${APIRoutes.AssetsModule.Components._componentsBaseUrl(assetId)}${ComponentTypesAPIURIs.EngineUnit}/`,
        GetAll: (assetId: string) => APIRoutes.AssetsModule.Components.EnginesController._engineUnitBaseUrl(assetId),
        GetExpandedEngines: (assetId: string) => `${APIRoutes.AssetsModule.Components.EnginesController._engineUnitBaseUrl(assetId)}expanded`,
        Update: (assetId: string) => `${APIRoutes.AssetsModule.Components.EnginesController._engineUnitBaseUrl(assetId)}`,
      },

      LandingGearsController: {
        _landingGearBaseUrl: (assetId: string) =>
          `${APIRoutes.AssetsModule.Components._componentsBaseUrl(assetId)}${ComponentTypesAPIURIs.LandingGear}/`,
        GetAll: (assetId: string) => APIRoutes.AssetsModule.Components.LandingGearsController._landingGearBaseUrl(assetId),
        GetExpandedLandingGears: (assetId: string) =>
          `${APIRoutes.AssetsModule.Components.LandingGearsController._landingGearBaseUrl(assetId)}expanded`,
        Update: (assetId: string) => `${APIRoutes.AssetsModule.Components.LandingGearsController._landingGearBaseUrl(assetId)}`,
      },
    },

    AircraftsController: {
      _aircraftsBaseUrl: `${_assetsModuleBaseModuleUrl}aircrafts/`,
      GetDetails: (assetId: string) => `${APIRoutes.AssetsModule.AircraftsController._aircraftsBaseUrl}${assetId}`,
      Update: (assetId: string) => `${APIRoutes.AssetsModule.AircraftsController._aircraftsBaseUrl}${assetId}`,
    },

    StandAloneEngineController: {
      _standAloneEnginesBaseUrl: `${_assetsModuleBaseModuleUrl}standaloneengine/`,
      GetStandAloneEngineDetails: (assetId: string) => `${APIRoutes.AssetsModule.StandAloneEngineController._standAloneEnginesBaseUrl}${assetId}`,
      Update: (assetId: string) => `${APIRoutes.AssetsModule.StandAloneEngineController._standAloneEnginesBaseUrl}${assetId}`,
    },

    AssetsController: {
      _assetsBaseUrl: `${_assetsModuleBaseModuleUrl}assets/`,
      GetAllAssets: () => APIRoutes.AssetsModule.AssetsController._assetsBaseUrl,
      GetAllFilters: () => `${APIRoutes.AssetsModule.AssetsController._assetsBaseUrl}filters`,
      GetFlyForwardDefaultParameters: (assetId: string) =>
        `${APIRoutes.AssetsModule.AssetsController._assetsBaseUrl}${assetId}/fly-forward-default-parameters`,
      Import: () => `${APIRoutes.AssetsModule.AssetsController._assetsBaseUrl}import`,
      Delete: (assetId: string) => `${APIRoutes.AssetsModule.AssetsController._assetsBaseUrl}${assetId}`,
    },
  },

  ForecastModule: {
    FlyForwardController: {
      _flyForwardBaseUrl: `${_forecastBaseModuleUrl}fly-forward/`,
      InitMultiAssetsFly: () => {
        return `${APIRoutes.ForecastModule.FlyForwardController._flyForwardBaseUrl}init-multi-assets-fly`
      },
      FlyForward: (reportItemId: string) => {
        return `${APIRoutes.ForecastModule.FlyForwardController._flyForwardBaseUrl}${reportItemId}`
      },
      ReFlyForwardWithManualEvent: (reportItemId: string) => {
        return `${APIRoutes.ForecastModule.FlyForwardController._flyForwardBaseUrl}${reportItemId}/reschedule-manual-event`
      },
    },
    ReportsController: {
      _reportsBaseUrl: `${_forecastBaseModuleUrl}reports/`,
      SearchReports: () => `${APIRoutes.ForecastModule.ReportsController._reportsBaseUrl}`,
      GetSingleReport: (id: string | null) => `${APIRoutes.ForecastModule.ReportsController._reportsBaseUrl}${id}`,
      Save: (id: string | null) => APIRoutes.ForecastModule.ReportsController.GetSingleReport(id),
      Delete: (id: string) => APIRoutes.ForecastModule.ReportsController.GetSingleReport(id),
      ExportReportItem: (reportId: string, reportItemId: string) =>
        `${APIRoutes.ForecastModule.ReportsController.GetSingleReport(reportId)}/report-item/${reportItemId}/export`,
      CustomExportReportItem: (reportId: string, reportItemId: string) =>
        `${APIRoutes.ForecastModule.ReportsController.GetSingleReport(reportId)}/report-item/${reportItemId}/custom-export`,
    },
    ReportItemController: {
      _reportItemBaseUrl: `${_forecastBaseModuleUrl}reports/report-item/`,
      GetSingleReportItem: (id: string | null) => `${APIRoutes.ForecastModule.ReportItemController._reportItemBaseUrl}${id}`,
    },
  },

  KnowledgeBaseModule: {
    MaintenancePlansController: {
      _maintenancePlansBaseUrl: `${_knowledgeBaseBaseModuleUrl}maintenanceplans/`,
      GetAll: () => `${APIRoutes.KnowledgeBaseModule.MaintenancePlansController._maintenancePlansBaseUrl}all`,
      ImportMaintenancePrograms: () => `${APIRoutes.KnowledgeBaseModule.MaintenancePlansController._maintenancePlansBaseUrl}import`,
      DeleteMaintenancePrograms: (maintenanceId: string) =>
        `${APIRoutes.KnowledgeBaseModule.MaintenancePlansController._maintenancePlansBaseUrl}${maintenanceId}`,
      ChangeStateMaintenancePrograms: (maintenanceId: string) =>
        `${APIRoutes.KnowledgeBaseModule.MaintenancePlansController._maintenancePlansBaseUrl}change-state/${maintenanceId}`,
    },

    MaintenanceProgramsController: {
      _maintenanceProgramsBaseUrl: `${_knowledgeBaseBaseModuleUrl}maintenanceprograms/`,

      EnginesPrograms: {
        GetAllEnginePrograms: () =>
          `${APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController._maintenanceProgramsBaseUrl}${ComponentTypesAPIURIs.EngineUnit}/`,
        GetSingleEngineProgram: (kbProgramId: string) =>
          `${APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.EnginesPrograms.GetAllEnginePrograms()}${kbProgramId}`,
        UpdateEngineProgram: (kbProgramId: string) =>
          `${APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.EnginesPrograms.GetAllEnginePrograms()}${kbProgramId}`,
      },

      AirframesPrograms: {
        GetAllAirframePrograms: () =>
          `${APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController._maintenanceProgramsBaseUrl}${ComponentTypesAPIURIs.Airframe}/`,
        GetSingleAirframeProgram: (kbProgramId: string) =>
          `${APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.AirframesPrograms.GetAllAirframePrograms()}${kbProgramId}`,
        UpdateAirframeProgram: (kbProgramId: string) =>
          `${APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.AirframesPrograms.GetAllAirframePrograms()}${kbProgramId}`,
      },

      LandingGears: {
        GetAllLandingGearMaintenancePrograms: () =>
          `${APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController._maintenanceProgramsBaseUrl}${ComponentTypesAPIURIs.LandingGear}/`,
        GetSingleLandingGearProgram: (kbProgramId: string) =>
          `${APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.LandingGears.GetAllLandingGearMaintenancePrograms()}${kbProgramId}`,
        UpdateLandingGearProgram: (kbProgramId: string) =>
          `${APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.LandingGears.GetAllLandingGearMaintenancePrograms()}${kbProgramId}`,
      },

      AuxiliaryPowerUnits: {
        GetAllAuxiliaryPowerUnitMaintenancePrograms: () =>
          `${APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController._maintenanceProgramsBaseUrl}${ComponentTypesAPIURIs.AuxiliaryPowerUnit}/`,
        GetSingleAuxiliaryPowerUnitProgram: (kbProgramId: string) =>
          `${APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.AuxiliaryPowerUnits.GetAllAuxiliaryPowerUnitMaintenancePrograms()}${kbProgramId}`,
        UpdateAuxiliaryPowerUnitProgram: (kbProgramId: string) =>
          `${APIRoutes.KnowledgeBaseModule.MaintenanceProgramsController.AuxiliaryPowerUnits.GetAllAuxiliaryPowerUnitMaintenancePrograms()}${kbProgramId}`,
      },
    },
  },
}
