/* eslint-disable max-len */
import { flexRender, type Row, type Table } from '@tanstack/react-table'
import { isNil } from 'lodash'
import { Button, ButtonVariant } from '../../Button'
import { IconVariant } from '../../Icon'
import { Size } from '../../common'

interface CustomColumnMeta {
  className?: string
}

interface IGenericTableProps<TData> {
  tableInstance: Table<TData>
  onRemoveRow?: (row: Row<TData>) => void
  onAddRow?: () => void
  showHeader?: boolean
}

const GenericTable = <TData,>({ tableInstance, onRemoveRow, onAddRow, showHeader = true }: IGenericTableProps<TData>) => {
  const rows = tableInstance.getRowModel().rows
  return (
    <div>
      <table className="w-full table-auto border-collapse border border-black-20">
        {showHeader && (
          <thead>
            {tableInstance.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="flex">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={`truncate border border-black-20 bg-primary-dark-1 ${!isNil(header) ? (header.column.columnDef.meta as CustomColumnMeta)?.className : ''}`}
                  >
                    {header.isPlaceholder ? null : <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>}
                  </th>
                ))}
                {!isNil(onRemoveRow) && <div className="h-7 w-5 gap-x-0 border border-black-20 bg-primary-dark-1 p-0"></div>}
              </tr>
            ))}
          </thead>
        )}
        <tbody>
          {rows?.map((row, rowIndex) => (
            <tr key={rowIndex} className="flex bg-header-table">
              {row.getVisibleCells().map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  className={`truncate border border-black-20 ${!isNil(cell) ? (cell.column.columnDef.meta as CustomColumnMeta)?.className : ''}`}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
              {!isNil(onRemoveRow) && (
                <td>
                  <Button
                    onClick={() => {
                      onRemoveRow(row)
                    }}
                    variant={ButtonVariant.Ghost}
                    size={Size.Small}
                    leftIcon={IconVariant.Delete}
                    className="h-6 gap-x-0 p-0"
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-1 flex justify-start">
        {!isNil(onAddRow) && (
          <Button size={Size.Small} onClick={onAddRow} variant={ButtonVariant.Ghost} leftIcon={IconVariant.Add} className="h-6 gap-x-0 p-0">
            Add Row
          </Button>
        )}
      </div>
    </div>
  )
}

export { GenericTable }
