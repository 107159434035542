import { type AircraftEngineDto, type EngineLifeLimitedPartDto } from '@flyward/assets/models'
import { useAssetComponentsForms } from '@flyward/assets/context/AssetComponentsFormsContext'
import { LLPTechnicalEditableTab } from './LLPTechnicalEditableTab'
import { LLPTechnicalReadOnlyTab } from './LLPTechnicalReadOnlyTab'
import {
  type UseFormUnregister,
  type Control,
  type FormState,
  type UseFormRegister,
  type UseFormSetValue,
  type UseFormTrigger,
} from 'react-hook-form'
import { type Row } from '@tanstack/react-table'
import { llpTechnicalEditableColumns, llpTechnicalNewEditableColumns } from './constants'
import { ComponentType, GUID_EMPTY, UtilizationUnits } from '@flyward/platform'

interface ILLPTechnicalTabProps {
  engineLifeLimitedParts: EngineLifeLimitedPartDto[]
  newEngineLifeLimitedParts: EngineLifeLimitedPartDto[]
  removedEngineLifeLimitedPartsIds: string[]
  registerFormField: UseFormRegister<AircraftEngineDto>
  unRegisterFormField: UseFormUnregister<AircraftEngineDto>
  setFormValue: UseFormSetValue<AircraftEngineDto>
  formControl: Control<AircraftEngineDto, unknown>
  formState: FormState<AircraftEngineDto>
  triggerComponentValidation: UseFormTrigger<AircraftEngineDto>
}

const LLPTechnicalTab = ({
  engineLifeLimitedParts,
  newEngineLifeLimitedParts,
  removedEngineLifeLimitedPartsIds,
  registerFormField,
  unRegisterFormField,
  setFormValue,
  formControl,
  formState,
  triggerComponentValidation,
}: ILLPTechnicalTabProps) => {
  const { isInEditMode } = useAssetComponentsForms()

  const onAddRow = () => {
    const firstLlp = engineLifeLimitedParts[0]
    const newRow: EngineLifeLimitedPartDto = {
      assetId: GUID_EMPTY,
      componentType: ComponentType.EngineLlp,
      componentId: GUID_EMPTY,
      technicalLimitUnit: UtilizationUnits.FlightCycles,
      technicalLimitAmount: 0,
      componentModule: '',
      componentModel: '',
      partNumber: '',
      componentSerialNumber: '',
      cyclesSinceNewAtContractDelivery: 0,
      assetComponentUtilizationSnapshot: {
        cyclesSinceNew: 0,
        totalHoursSinceNew: 0,
        cyclesSinceEvent: 0,
        remainingCycles: 0,
        dateOfLastSnapshot: firstLlp?.assetComponentUtilizationSnapshot?.dateOfLastSnapshot ?? new Date(),
        componentUtilizationAtEvents: [],
      },
    }
    const updatedData = [...newEngineLifeLimitedParts, newRow]
    registerFormField(`addRemoveLlps.addedItems.${updatedData.length - 1}`)
    setFormValue(`addRemoveLlps.addedItems.${updatedData.length - 1}`, newRow, {
      shouldDirty: true,
    })
    setFormValue('addRemoveLlps.addedItems', updatedData, { shouldDirty: true })
  }

  const onExistingRowRemove = (row: Row<EngineLifeLimitedPartDto>) => {
    const updatedData = engineLifeLimitedParts.filter((_data, index) => index !== row.index)
    unRegisterFormField(`engineLifeLimitedParts.${row.index}`)
    setFormValue('engineLifeLimitedParts', updatedData, { shouldDirty: true })

    const removedIds = [...removedEngineLifeLimitedPartsIds, row.original.componentId]
    registerFormField(`addRemoveLlps.removedItemIds.${updatedData.length - 1}`)
    setFormValue('addRemoveLlps.removedItemIds', removedIds, { shouldDirty: true })
  }

  const onNewRowRemove = (row: Row<EngineLifeLimitedPartDto>) => {
    const updatedData = newEngineLifeLimitedParts.filter((_data, index) => index !== row.index)
    unRegisterFormField(`addRemoveLlps.addedItems.${row.index}`)
    setFormValue('addRemoveLlps.addedItems', updatedData, { shouldDirty: true })
    triggerComponentValidation()
  }

  const existingItemsColumns = llpTechnicalEditableColumns(formControl)
  const newItemsColumns = llpTechnicalNewEditableColumns(formControl)

  return (
    <div className="w-368">
      {isInEditMode ? (
        <>
          <LLPTechnicalEditableTab
            engineLifeLimitedParts={engineLifeLimitedParts}
            registerFormField={registerFormField}
            unRegisterFormField={unRegisterFormField}
            setFormValue={setFormValue}
            formControl={formControl}
            formState={formState}
            showHeader={true}
            onRowRemove={onExistingRowRemove}
            columns={existingItemsColumns}
          />
          <LLPTechnicalEditableTab
            engineLifeLimitedParts={newEngineLifeLimitedParts}
            registerFormField={registerFormField}
            unRegisterFormField={unRegisterFormField}
            setFormValue={setFormValue}
            formControl={formControl}
            formState={formState}
            onAddRow={onAddRow}
            showHeader={false}
            onRowRemove={onNewRowRemove}
            columns={newItemsColumns}
          />
        </>
      ) : (
        <LLPTechnicalReadOnlyTab engineLifeLimitedParts={engineLifeLimitedParts} />
      )}
    </div>
  )
}

export { LLPTechnicalTab }
export type { ILLPTechnicalTabProps }
